import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useChecklistsListList() {

  const type_Data = ref([]);
  const category_Data = ref([]);
  const advice_Data = ref([]);
  const searchQuery = ref('');
  const selected_group = ref(0);
  const selected_category = ref(0);
  const selected_type = ref(0);
  const selected_status = ref(0);
  const refListTable = ref(null);
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
  const tableColumns = [

    { field: 'id', label: 'id', sortable: false, hidden:true },
    { field: 'code', label: 'รหัส', sortable: false },
      { field: 'title', label: 'ชื่อ', sortable: false },


      { field: 'price', label: 'ราคา', sortable: false },


      { field: 'is_active', label: 'สถานะ', sortable: false },
      { field: 'action', label: 'การจัดการ', sortable: false },


    ]
    const Data = ref([]);


    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;

    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery,selected_group,selected_category,selected_type,selected_status], () => {
      fetchData();
    })

    const fetchData = async () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        await store
          .dispatch('app-checklists/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,
            is_active:selected_status.value,
            branch_id:branch_id,
            order_by:'checklists_set.id DESC'
         
          })
          .then(response => {
          
            let data = response.data.data;
            response.data.data.forEach((data) => data.balance = data.stock_in - data.stock_out);

            Data.value = response.data.data;
            console.log('checklists checklists value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching checklists' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }


  fetchData();

  const deleteData = (id) => {
    //fetchDeleteProperty
    var data = {id:id}
    
    var res = store.dispatch('app-checklists/deleteData',data)
    res.then(response=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      fetchData();
    }).catch(()=>{
      toast({
        component: ToastificationContent,
        props: {
          title: "Error Delete",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })

    })
    //console.log('id', id);
    
  }
  const resolveUserStatusVariant = status => {
       
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusnameVariant = status => {
  
      if (status) return 'Active'
      if (!status) return 'Inactive'
      return 'primary'
  }
  const resolveChecklistsListVariant = status =>{
    if (status == 'วัสดุ') return 'dark'
    else if (status == 'บริการ') return 'warning'
    return 'light'
  }
  const resolveChecklistsListGroupnameVariant = status =>{
    if (status == 'วัสดุ') return 'ShieldIcon'
    else if (status == 'บริการ') return 'SmileIcon'
    return 'light'
  }
    return {
        fetchData,
        tableColumns,
        type_Data,
        category_Data,
        advice_Data,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        selected_group,
        selected_category,
        selected_type,
        selected_status,
        currentPage,
        refListTable,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
        resolveChecklistsListVariant,
        resolveChecklistsListGroupnameVariant
    }
}